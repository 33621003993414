<template>
	<div class="page">
		<div class="s1 p-card m-3 pt-2 fs-12 color-gray">
			<p class="ml-3 fs-16 color-black b">官方素材</p>

			<div class="ml-3 mt-1 flex v-top mr-3">
				<div>推送内容：</div>
				<div class="flex-1">品类丰富，利益点突出，卖点精炼，普及性强涉猎面广，潮流前线</div>
			</div>
			<div class="ml-3 flex mt-1">
				<div>适合人群：</div>
				全体人群
			</div>
			<div class="ml-3 flex mt-1">
				<div>播货时间：</div>
				08:00~23:00
			</div>
			<div class="ml-3 pb-2 flex mt-1">
				<div>播货数量：</div>
				25~45
			</div>
		</div>
		<TimePicker @confirm="timePickerConfirm"
		            :startTime="timeRange[0]" :endTime="timeRange[1]"/>
		<ul class="s2 p-card m-3 material-box">
			<!--官方分组-->
			<router-link
					v-bury="'guanfangsucaiyulan'"
					tag="li" class="material-item flex " v-for="(item, index) in officialGroup" :key="item.officalId"
					:to="{ path: '/officialMaterial', query: {officalId : item.officalId}}">
				<div class="item-index fs-14 b">{{ index + 1 }}</div>
				<div class="item-context ml-1 flex-1">
					<div class="flex h-between">
						<div class="fs-14 flex-1 text-flow">{{ item.title }}</div>
						<div class="flex-right flex">
							<i class="icon-preview"/>
							<span class="color-primary ml-1">预览</span>
						</div>
					</div>
					<div class="group-info mt-2 fs-12">
						{{ item.desc ? item.desc : '暂未设置群介绍' }}
					</div>
				</div>
			</router-link>
			<!--官方素材-->
			<li v-bury="'guanfangsucaiyulan'"
			    class="material-item flex " v-for="(item, index) in officialMaterial" :key="item.chatRoomId"
			    @click="previewMaterial(item)">
				<div class="item-index fs-14 b">{{ index + officialGroupLength + 1 }}</div>
				<div class="item-context ml-1 flex-1">
					<div class="flex h-between">
						<div class="fs-14 flex-1 text-flow">{{ item.groupName }}</div>
						<div class="flex-right flex">
							<i class="icon-preview"/>
							<span class="color-primary ml-1">预览</span>
						</div>
					</div>
					<div class="group-info mt-2 fs-12">
						{{ item.desc ? item.desc : '暂未设置群介绍' }}
					</div>
				</div>
			</li>

		</ul>


		<chat-list :officialMaterialInfo="officialMaterialInfo" v-model:show="dialog1"/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import chatList from '@/components/chatList/index.vue'
import { VueDraggableNext } from 'vue-draggable-next'
import { defineAsyncComponent } from 'vue'
import { ApiOfficalPushGroupList, ApiQueryOfficialChatrooms } from '../../api/FollowLive'
import { Toast } from 'vant'
import { ApiGetUserPushTimeRange, ApiSetUserPushTimeRange } from '../../api/PushProduct'

export default {
	name: 'Index',
	components: {
		chatList,
		TimePicker: defineAsyncComponent(() => import('@/components/TimePicker'))
	},
	data() {
		return {
			// 官方分组
			officialGroup: [],
			// 官方素材群
			officialMaterial: [],

			dialog1: false,
			timeRange: ['08:30', '12:00'], // 开始/结束时间
			dialog3: false,
			columns: [],
			list: [],
			loading: false,
			finished: false,
			pageIndex: 1,
			pageSize: 15,
			officialMaterialInfo: {}
		}
	},
	computed: {
		...mapGetters(['globalData']),
		officialGroupLength() {
			return this.officialGroup.length
		}
	},
	async created() {
		const toast = Toast.loading({
			message: '数据加载中...',
			forbidClick: true,
			loadingType: 'spinner',
			duration: 0
		})
		await this.getPushTime()
		await this.getOfficialMaterial()
		await this.getOfficialGroup()
		toast.clear()

	},
	methods: {
		async getPushTime() {
			try {
				const res = await ApiGetUserPushTimeRange()
				this.timeRange = res ? [res.startTimeRange, res.endTimeRange] : this.timeRange
			} catch (e) {
				console.warn('ApiGetUserPushTimeRange', e)
			}
		},
		// 获取官方分组群
		async getOfficialGroup() {
			try {
				const res = await ApiOfficalPushGroupList()
				this.officialGroup = res
				console.log(res)
			} catch (e) {
				console.log(e)
			}
		},

		// 获取官方素材群
		async getOfficialMaterial() {
			try {
				const res = await ApiQueryOfficialChatrooms()
				this.officialMaterial = res.map(i => {
					i.groupName = i.showName ?
							i.showName :
							(i.chatRoomName ? i.chatRoomName : '未命名')
					return i
				})
				console.log(res)
			} catch (e) {
				console.log(e)
			}
		},

		/**
		 * 官方素材预览
		 * @param item
		 */
		previewMaterial(item) {
			this.officialMaterialInfo = {
				chatroomId: item.chatRoomId,
				robotWxId: item.robotWxId
			}
			this.dialog1 = true
		},
		goEdit() {
			this.$store.commit('setGlobalData', {
				goods: this.list
			})
			this.$router.push('/todayPushEdit')
		},
		async timePickerConfirm(startTime, endTime) {
			try {
				this.timeRange[0] = startTime
				this.timeRange[1] = endTime
				this.$Toast.loading({
					message: '提交中...',
					forbidClick: true
				})
				await ApiSetUserPushTimeRange({
					startTimeRange: this.timeRange[0],
					endTimeRange: this.timeRange[1]
				})
				this.dialog3 = false
				this.$Toast('修改成功')
				this.$Toast.clear()
			} catch (e) {
				this.$Toast.clear()

				console.warn('ApiSetUserPushTimeRange', e)
			}
		}
	}
}
</script>

<style scoped lang="scss">
@import "index";

.material-box {
	padding: px(15);

	.material-item {
		margin-bottom: px(15);
		width: 100%;
		align-items: baseline;

		&:last-of-type {
			margin-bottom: 0;
		}

		.item-index {
			width: px(22);
			height: px(22);
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #433C3C;
			line-height: px(22);
			border: 1px solid #433C3C;
			box-sizing: border-box;
			border-radius: 50%;
			text-align: center;
		}

		.item-context {
			width: 100%;
			color: #433C3C;

			.icon-preview {
				margin-top: px(-4);
				display: inline-block;
				width: px(18);
				height: px(18);
				mask-image: url("assets/icon-1.png");
				mask-repeat: no-repeat;
				mask-size: contain;
				mask-position: center;
				background: $color-primary;
			}

			.group-info {
				white-space: pre-line;
				padding: px(8);
				background: #F6F6F6;
				border-radius: px(4);
			}
		}


	}
}

</style>
